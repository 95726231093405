
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import Home from './Routes/Home/Home'
import Layout from './Layout'
import Contact from './Routes/Contact/Contact'
import Why from './Routes/WhyPage/Why'
import ClientPage from './Routes/Clients/ClientPage';
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback }  from './Components/ErrorFallback'
const Portfolio = lazy(() => import('./Routes/Portfolio/Portfolio'))

function App() {
  const navigate = useNavigate()
  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='/portfolio' element={
        <ErrorBoundary
          fallback={<ErrorFallback/>}
          onReset={() => navigate('/')}
          >
        <Suspense>
          <Portfolio />
        </Suspense>
        </ErrorBoundary>}
        >
        </Route>
        <Route path='/Contact' element={<Contact />} />
        <Route path='/why' element={<Why />} />
        <Route path='/clients' element={<ClientPage />} />
      </Route>
    </Routes>
    </>
  );
}

export default App;
