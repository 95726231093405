import './clientsection.css'
import { clientsData } from './clientsData'
import {useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

export default function ClientSection() {
    const [canSee, setCanSee] = useState(false)
    const [ref, inView] = useInView({ threshold: 0.5})

    const container = {
      hidden: { opacity: 0, x: 100, },
      show: {
        x: 0,
        opacity: 1,
        transition: {
          ease: "easeOut", 
          duration: 1,
          staggerChildren: 0.23
        }
      }
    }
    const item = {
      hidden: { opacity: 0 },
      show: { opacity: 1 },
      
    }
    useEffect(() => {
      if (inView == true) {
        setCanSee(true)
      }
    })
    return (
        <div className='clientSectionContainer' ref={ref}>
            {canSee ?
            <motion.div className='projectItems'
                variants={container}
                initial="hidden"
                animate="show"
            >
            {clientsData.map((c, index) => {
                return (
                <a href={c.link} target='_blank'>
                <motion.div className='project' variants={item}>
                    <img src={c.logo} className='projectImg' loading="lazy"/>
                </motion.div>
                </a>
                )
            })}
            </motion.div>
            : null}
        </div>
    )
}