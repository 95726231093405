import "./herostyles.css"
import logo from '../../../Images/cssnow.png'
import Nav from '../../../Components/Nav/Nav'
import {useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

export default function Hero() {
    const [canSee, setCanSee] = useState(false)
    const [ref, inView] = useInView({ threshold: 0.5})

    const container = {
      hidden: { opacity: 0, x: 100 },
      show: {
        x: 0,
        opacity: 1,
        transition: {
          ease: "easeOut", 
          duration: 1.3,
          staggerChildren: 0.23
        }
      }
    }
    const item = {
      hidden: { opacity: 0 },
      show: { opacity: 1 },
      
    }
    useEffect(() => {
      if (inView == true) {
        setCanSee(true)
      }
    })
    return (
        <div className="heroContainer" ref={ref}>
            <Nav />
            {canSee ?
            
            <motion.div className='heroItems' 
                variants={container}
                initial="hidden"
                animate="show"        
                >
                <div className='heroItem' variants={item}>
                    <img src={logo} alt='CSavia Solutions Logo' className='logo' loading="lazy"/>
                    <h3>We help businesses</h3>
                    <h1>Build better websites</h1>
                    <div className="buttonContainer">
                        <Link to='/portfolio'><button className='heroButton'>Portfolio</button></Link>
                        <Link to='/contact'><button className='heroButton'>Contact</button></Link>
                    </div>
                </div>
                <motion.div className='heroItem'  variants={item}> 
                    <div className='squareItems' >
                    <img className='heroSquare square1' loading="lazy" />
                    <img className='heroSquare square2' loading="lazy" />
                    </div>
                    <div className='squareItems'  >
                    <img className='heroSquare square3' loading="lazy"/>
                    <img className='heroSquare square4' loading="lazy"/>
                    </div>
                </motion.div>
            </motion.div>
                : null}
            <div></div>
        </div>
    )
}