import './clientStyles.css'
import { ClientData } from './ClientData'
import Nav from '../../Components/Nav/Nav'
export default function ClientPage() {
    return (
        <div className="clientPageContainer">
        <div className="otherPageNav">
            <Nav />
        </div>
            <div className='clientItems'>
            {ClientData.map((c, index) => (
                    <div className='clientItem' key={index}> 
                    <div className='clientImage'>
                    <img src={c.logo}/>
                    </div>
                        <h2>{c.name}</h2>
                        <h3>Services</h3>
                        <p>{c.services}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}