import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import { AiFillMail } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard' 
import { Link } from 'react-router-dom'
import Contact from '../Home/ContactSection/ContactSection'
import Nav from '../../Components/Nav/Nav'

export default function contact() {
    return( 
        <div className='contactPage'>
        <div className="otherPageNav">
        <Nav />
        </div>
        <Contact />
        </div>
    )
}