import './whyStyles.css'
import { useState, useEffect, useRef, useMemo } from 'react'
import Nav from '../../Components/Nav/Nav'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer'
import section1 from '../../Images/section1.png'
import section2 from '../../Images/section2.png'
import section3 from '../../Images/section1.png'
import section4 from '../../Images/section4.png'
import section5 from '../../Images/section5.png'
import { motion } from 'framer-motion'
export default function Why() {
    const [ref, inView] = useInView({ threshold: 0.4 })
    const [ref2, inView2] = useInView({ threshold: 0.4 })
    const [ref3, inView3] = useInView({ threshold: 0.4 })
    const [ref4, inView4] = useInView({ threshold: 0.4 })
    const [canSee, setCanSee] = useState(false)
    const [canSee2, setCanSee2] = useState(false)
    const [canSee3, setCanSee3] = useState(false)
    const [canSee4, setCanSee4] = useState(false)
    


    const [counterOn, setCounterOn] = useState(inView)
    const [counterOn2, setCounterOn2] = useState(inView2)
    const [counterOn3, setCounterOn3] = useState(inView3)
    const [counterOn4, setCounterOn4] = useState(inView4)

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    useEffect(() => {
        if(inView == true) {
            setCanSee(true)
            setCounterOn(true)
        } else {
            setCounterOn(false) 
        }
        if(inView2 == true) {
            setCanSee2(true)
            setCounterOn2(true)
        } else {
            setCounterOn2(false) 
        }
        if(inView3 == true) {
            setCanSee3(true)
            setCounterOn3(true)
        } else {
            setCounterOn3(false) 
        }
        if(inView4 == true) {
            setCanSee4(true)
            setCounterOn4(true)
        } else {
            setCounterOn4(false) 
        }
    })
    return (
        <div className='whyContainer'>
            <div className="otherPageNav ">
            <Nav />
            </div>
            <div className='whyPageItems'>
                <div className='whyPageItem' ref={ref}>
                {canSee ?
                    <>
                    <motion.div className="whyItemInner"
                        style={{x: -100, opacity: 1, transition: {duration: 1}}}
                        animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                    >
                    <h3>Why CSAVIA SOLUTIONS</h3>
                    <p>
                    In a fast paced world people make up their minds 
                    in 90 seconds. Give your  website a new modern look.
                    </p>
                    <div className='countUp'> <CountUp start={0} end={90} duraction={2} delay={0.5}></CountUp>%</div>
                    
                    </motion.div>
                    <motion.div className='whyItemInner whyItemBg'
                        style={{x: 100, opacity: 1, transition: {duration: 1}}}
                        animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                    >
                    <img src={section1} className="whyImage" />
                    </motion.div>
                    </>
                    : null}
                </div>
                <div className='whyPageItem' ref={ref2}>
                    {canSee2 ?
                    <>
                    <motion.div className="whyItemInner"
                        style={{x: -100, opacity: 1, transition: {duration: 1}}}
                        animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                    >
                    <h3>When optimized for Mobile</h3>
                    <p>
                    With half of website traffic being mobile sales increase
                    By 62% when optimized for mobile    
                    </p>
                    <div className='countUp'> <CountUp start={0} end={62} duraction={2} delay={0.5}></CountUp>%</div>
                    </motion.div>
                    <motion.div className='whyItemInner whyItemBg'
                        style={{x: 100, opacity: 1, transition: {duration: 1}}}
                        animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                    >
                        <img src={section2} className="whyImage" />
                    </motion.div>
                    </>
                    : null}
                </div>
                <div className='whyPageItem' ref={ref3}>
                {canSee3 ?
                     <>
                    <motion.div className="whyItemInner"
                         style={{x: -100, opacity: 1, transition: {duration: 1}}}
                         animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                    >
                    <h3>With Slow Website Speeds</h3>
                    <p>
                    With short attention spans, slow websites
                    Decrease sales by 27%
                    </p>
                    <div className='countUp'> <CountUp start={0} end={27} duraction={2} delay={0.5}></CountUp>%</div>
                    </motion.div>
                    <motion.div className='whyItemInner whyItemBg'
                        style={{x: 100, opacity: 1, transition: {duration: 1}}}
                        animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                    >
                    <img src={section4} className="whyImage" />
                    </motion.div>
                    </>
                    : null }
                </div>
                <div className='whyPageItem' ref={ref4}>
                {canSee4 ?
                <>
                    <motion.div className="whyItemInner"
                        style={{x: -100, opacity: 1, transition: {duration: 1}}}
                        animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                    >
                    <h3>How Does Graphic Design Affect Business</h3>
                    <p>
                    Social media is essential for brand presence
                    94% of impressions of a company are design related
                    </p>
                    <div className='countUp'> <CountUp start={0} end={94} duraction={2} delay={0.5}></CountUp>%</div>
                    </motion.div>
                    <motion.div className='whyItemInner whyItemBg'
                        style={{x: 100, opacity: 1, transition: {duration: 1}}}
                        animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                    >
                    <img src={section5} className="whyImage" />
                    </motion.div>
                    </>
                    : null}
                </div>
            </div>
        </div>
    )
}