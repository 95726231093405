import angryjoe from '../../Images/AngryJoeLogo.png'
import outspoken from '../../Images/outspokenb.png'

export const ClientData = [
{
    name: "Angry Joe BBQ",
    logo: angryjoe,
    services: "Web Development, Social Media"
},
{
    name: "OutSpoken Brewing",
    logo: outspoken,
    services: "Web Development"
}
]