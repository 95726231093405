import { Link } from 'react-router-dom'
import './footerStyles.css'
import logo from '../../Images/CSW.png'

export default function Footer() {
    return (
        <div className='footerContainer'>
        <div className='footerSide'>
        <div className='footerSection'>
            <h3>Pages</h3>
            <Link to="/"><h2 className="pageLinks" >Home</h2></Link>
            <Link to="/portfolio"><h2 className="pageLinks" >Portfolio</h2></Link>
            <Link to="/clients"><h2 className="pageLinks" >Clients</h2></Link>
            <Link to="/contact"><h2 className="pageLinks" >Contact</h2></Link>
        </div>
        <div className='footerSection'>
            <h3>Socials</h3>
            <a className='footerText'href="https://www.instagram.com/csaviasolutions/" target='_blank'>Instagram</a><br/>
            <br/>
            <h3>Contact</h3>
            <p className='footerText'>cole@csaviasolutions.com</p>
            <p className='footerText'>(705) 971-3462</p>
        </div>
        </div>
        <div className='footerSide'>
            <img className='footerLogo' src={logo} />
        </div>
    </div>
    )
}