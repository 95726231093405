import './serviceSectionStyles.css'
import {useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
export default function ServicesSection() {
    const [canSee, setCanSee] = useState(false)
    const [ref, inView] = useInView({ threshold: 0.5})

    const container = {
      hidden: { opacity: 0, x: 100, },
      show: {
        x: 0,
        opacity: 1,
        transition: {
          ease: "easeOut", 
          duration: 1,
          staggerChildren: 0.23
        }
      }
    }
    const item = {
      hidden: { opacity: 0 },
      show: { opacity: 1 },
      
    }
    useEffect(() => {
      if (inView == true) {
        setCanSee(true)
      }
    })

    return (
        <div className='serviceSectionContainer' ref={ref}>
            {canSee ? 
            <>
            <h2>Services</h2>
            <motion.div className='cardContainer' 
                    variants={container}
                    initial="hidden"
                    animate="show"
                    >
                <motion.img className='serviceCard card1' variants={item} />

                <motion.img className='serviceCard card2' variants={item} />
                    
                <motion.img className='serviceCard card3' variants={item} />
                    
                <motion.img className='serviceCard card4' variants={item} />
                    
            </motion.div>
            </>
            : null }
        </div>
    )
}