import outspoken from '../../../Images/outspokenLogo.png'
import angryJoeBBQ from '../../../Images/AngryJoeLogo.png'
import bears from '../../../Images/bearlogo.png'

export const clientsData = [
    {
        Name: "OutSpoken Brewery",
        logo: outspoken,
        link: "https://outspoken-e8fa4.web.app/"
    },
    {
        Name: "Angry Joe's BBQ",
        logo: angryJoeBBQ,
        link: "https://angryjoesbbq.ca/"
    },
    {
        Name: "Bear Sault Ste Marie",
        logo: bears,
        link: "https://bearsssm.ca/"
    }
]