import Hero from './Hero/Hero'
import ClientsSection from './ClientsSection/ClientSection'
import WhySection from './WhySection/WhySection'
import ServicesSection from './ServicesSection/ServicesSection'
import ContactSection from './ContactSection/ContactSection'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import React, { useRef, useEffect } from 'react'


export default function Home() {


    return (
        <div className="homeContainer">
            <Hero/>
            <ClientsSection/>
            <WhySection />
            <ServicesSection />
            <ContactSection />
        </div>
    )
}