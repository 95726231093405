import './whysectionStyles.css'
import sectionImg from '../../../Images/website.png'
import { Link }  from 'react-router-dom'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useState, useEffect} from 'react'
export default function WhySection() {
    const [ref, inView] = useInView({ threshold: 0.6})
    const [canSee, setCanSee] = useState(false)
    useEffect(() => {
        if (inView == true) {
          setCanSee(true)
        }
      })

    return (
        <div className='whySectionContainer' ref={ref}>
            {canSee ?
            <div className='whyItems'>
                <div className='whyLeft'>
                    <h3>Why CSavia Solutions</h3>
                    <motion.div className='whyItem'
                    style={{x: -100, opacity: 0, transition: {duration: 1}}}
                    animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                    >
                    <h2>Give your website a <span> professional modern </span> new look</h2>
                    <p>In this modern age web presence is essential. Many factors
                        go into getting new customer and retaining old ones. Such as 
                        website speeds, mobile responsive, SEO, branding and social media</p>
                    <Link to='/why'><button className='whyButton'> Learn More</button></Link>
                    </motion.div>
                    <div />
                </div>
                <motion.div className='whyRight whyColor'
                    style={{x: 100, opacity: 0, transition: {duration: 1}}}
                    animate={{ x: 0, opacity: 1, transition: {duration: 1}}}
                >
                    <img src={sectionImg} alt='Website image'  loading="lazy"/>
                </motion.div>
            </div>
            : null }
        </div>
    )
}