import './contactStyles.css'
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import { AiFillMail } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard' 
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer';

export default function ContactSection() {
    const [inputValue, setInputValue] = useState('COLE@CSAVIASOLUTIONS.COM')
    const [isActive, setIsActive] = useState(true)
    const [seconds, setSeconds] = useState(0)
    const [ref, inView] = useInView({ threshold: 0.6})
    const [canSee, setCanSee] = useState(false)

    useEffect(() => {
        if (inView == true) {
          setCanSee(true)
        }
      })
    const handleClick = () => {
      console.log(isActive)
      setIsActive(false)
  
      navigator.clipboard.writeText('COLE@CSAVIASOLUTIONS.COM')
    }
  
    useEffect(() => {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
        setIsActive(true)
      }, 500);
      return () => clearInterval(interval);
    }, [isActive == false]);

    return (
        <div className='contactSectionContainer' ref={ref}>
            {canSee ? 
            <motion.div className='contactItems'
                style={{y: -100, opacity: 0, transition: {duration: 1}}}
                animate={{ x: 0, opacity: 1, transition: {duration: 1}}}>
                <motion.h3

                >
                    Interested? <br /> Get in contact</motion.h3>
                <motion.CopyToClipboard 
                text={inputValue}

                >
                    <div className={isActive ? 'emailBox' : 'emailBoxCopied'}

                    onClick={() => handleClick()}>
                        <div className='emailIconBox'>
                            <div className='mailIcon'><AiFillMail /></div>
                        </div>
                        <div className='emailText'>
                            <h2 className="emailText">cole@csaviaSolutions.com</h2>
                            <div className='textBoxBg' />
                        </div>
                    </div>
                </motion.CopyToClipboard>

            </motion.div>
            : null }
        </div>
    )
}