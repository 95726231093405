import navStyles from './navStyles.css'
import { Link } from 'react-router-dom'
import { FaTimes, FaBars} from "react-icons/fa"
import { useRef } from 'react'
import "./navStyles.css"

export default function Nav() {
    const navRef = useRef();

    const showNavbar = () => {
      navRef.current.classList.toggle("responsive_nav");
    }
    const hideNavbar =()=>{
      navRef.current.classList.remove("responsive_nav")
    }

    return (
        <div className="navbar" style={{y: -50}} animate={{y: 5}}
        transition={{duration: 1}}>
         <div className='navMobileItems' />


        <nav ref={navRef} className="">
        <div className='navbarContainer'>
        <div className='navbarLinks'>
          <Link to='/'><a href="/"><h2 className="navLinks" onClick={hideNavbar}>Home</h2></a></Link>
          <Link to='/portfolio'><a href="portfolio"><h2 className="navLinks" onClick={hideNavbar} >Portfolio</h2></a></Link>
          <Link to='/clients'><a href="clients"><h2 className="navLinks" onClick={hideNavbar}>Clients</h2></a></Link>
          <Link to='/contact'><a href="contact"><h2 className="navLinks" onClick={hideNavbar}>Contact</h2></a></Link>
         </div>
       </div>
         <button 
         className="nav-btn nav-close-btn"
         onClick={showNavbar}>
             <FaTimes />
         </button>
        </nav>
        <div className="navMobileItems">
        <button 
        className="nav-btn" onClick={showNavbar}>
           <FaBars />
        </button>
        </div>
        </div>
    )
}